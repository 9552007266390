<template>
  <q-btn dense flat color="red" icon="delete" @click="alert = true" />

  <q-dialog v-model="alert">
    <q-card style="width: 450px" class="dialog-form">
      <q-card-section class="q-ma-sm bg-grey-4">
        <span v-html="$t('signup.parameterized_registration.delete_doc')" />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat color="black" :label="$t('global.cancel')" v-close-popup />
        <q-btn
          color="black"
          :loading="in_request_delete"
          :label="$t('global.confirm')"
          @click="deleteDoc(item.id)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { uploadServices } from "../setup/uploadWithCustomJwt";
import { useActions } from "vuex-composition-helpers";
import { ref, defineProps } from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  uploadToAnotherCompany: {
    type: Boolean,
    default: false,
  },
});

const alert = ref(false);
const in_request_delete = ref(false);

const { fetchUserDocs, fetchDeleteDoc, fetchDocDataToCustomJwt } = useActions({
  fetchUserDocs: "account/fetchUserDocs",
  fetchDeleteDoc: "account/fetchDeleteDoc",
  fetchDocDataToCustomJwt: "account/fetchDocDataToCustomJwt",
});

const deleteDoc = async (id) => {
  in_request_delete.value = true;

  if (props.uploadToAnotherCompany) {
    await uploadServices
      .fetchDeleteDoc(id)
      .then(() => {
        fetchDocDataToCustomJwt()
          .then(() => (alert.value = false))
          .finally(() => (in_request_delete.value = false));
      })
      .finally(() => (in_request_delete.value = false));
  } else {
    await fetchDeleteDoc({ id }).then(() =>
      fetchUserDocs()
        .then(() => (alert.value = false))
        .finally(() => (in_request_delete.value = false))
    );
  }
};
</script>
