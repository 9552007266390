<template>
  <template
    v-for="(doc, i) in formatOptions(docTypes, getUserDocuments)"
    :key="i"
  >
    <div class="bg-grey-2 q-pa-md text-weight-medium" v-html="doc.label" />
    <CardToSendDoc
      :doc="doc"
      :docsLengthIsMoreThenOne="true"
      :fetchOrUpdateUser="fetchOrUpdateUser"
      :isParameterizedForm="isParameterizedForm"
      :uploadToAnotherCompany="uploadToAnotherCompany"
    />
  </template>
</template>

<script setup>
import { useGetters } from "vuex-composition-helpers";
import { formatOptions } from "../setup";

import CardToSendDoc from "./CardToSendDoc.vue";

import { defineProps } from "vue";

defineProps({
  fetchOrUpdateUser: {
    type: Boolean,
    default: false,
  },
  isParameterizedForm: {
    type: Boolean,
    default: false,
  },
  uploadToAnotherCompany: {
    type: Boolean,
    default: false,
  },
});

const { getUserDocuments, docTypes } = useGetters({
  getUserDocuments: "account/getUserDocuments",
  docTypes: "account/getDocTypes",
});
</script>
