<template>
  <q-card
    flat
    bordered
    class="q-pa-md bg-grey-2"
    :class="{ 'bg-grey-2': isOdd(i) }"
  >
    <div class="row items-center">
      <div class="q-ml-sm">
        {{ getDocName(item.slug_doc, docTypes) }}
        <div>
          <q-icon name="download_for_offline" class="q-mr-xs" />
          <a
            target="_blank"
            :href="item.url"
            style="text-decoration: none"
            :download="item.original_file_name"
            v-html="$t('signup.parameterized_registration.download_file')"
          />
        </div>
      </div>
      <q-space />
      <DeleteDoc
        :item="item"
        :uploadToAnotherCompany="uploadToAnotherCompany"
      />
    </div>
  </q-card>
</template>

<script setup>
import { useGetters } from "vuex-composition-helpers";
import { getDocName, isOdd } from "../setup";
import { defineProps } from "vue";

import DeleteDoc from "./DeleteDoc.vue";

defineProps({
  item: {
    type: Object,
    required: true,
  },
  uploadToAnotherCompany: {
    type: Boolean,
    default: false,
  },
});

const { docTypes } = useGetters({
  docTypes: "account/getDocTypes",
});
</script>
