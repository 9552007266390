<template>
  <div v-show="formatOptions(docTypes, getUserDocuments).length">
    <div
      class="q-mb-md text-weight-bold"
      v-html="$t('signup.parameterized_registration.send_this_docs')"
    />
    <q-card flat bordered>
      <div v-show="formatOptions(docTypes, getUserDocuments).length">
        <template v-if="formatOptions(docTypes, getUserDocuments).length > 1">
          <ListDocs
            :fetchOrUpdateUser="fetchOrUpdateUser"
            :isParameterizedForm="isParameterizedForm"
            :uploadToAnotherCompany="uploadToAnotherCompany"
          />
        </template>
        <template v-if="formatOptions(docTypes, getUserDocuments).length == 1">
          <OneDoc
            :fetchOrUpdateUser="fetchOrUpdateUser"
            :isParameterizedForm="isParameterizedForm"
            :uploadToAnotherCompany="uploadToAnotherCompany"
          />
        </template>
      </div>
    </q-card>
  </div>

  <div v-if="getUserDocuments.length && showUserDocuments">
    <div
      class="q-mb-md text-weight-bold"
      :class="{ 'q-mt-md': formatOptions(docTypes, getUserDocuments).length }"
      v-html="$t('signup.parameterized_registration.uploads')"
    />
    <div v-for="(item, i) in getUserDocuments" :key="i">
      <CardDocUploaded
        :item="item"
        :uploadToAnotherCompany="uploadToAnotherCompany"
      />
    </div>
    <div
      class="text-grey-7 q-pa-sm q-mt-sm text-italic"
      v-html="$t('account.my_docs.send_again')"
    />
  </div>

  <div
    v-show="!formatOptions(docTypes, getUserDocuments).length"
    class="q-mt-sm bg-grey-3 q-pa-md text-center"
  >
    <q-icon name="check" size="22px" class="q-mr-sm" />
    <span v-html="$t(defaultSuccessMsg)" />
  </div>
</template>

<script setup>
import { useGetters } from "vuex-composition-helpers";
import { formatOptions } from "./setup";

import CardDocUploaded from "./components/CardDocUploaded.vue";
import ListDocs from "./components/ListDocs.vue";
import OneDoc from "./components/OneDoc.vue";

import { defineProps } from "vue";

defineProps({
  fetchOrUpdateUser: {
    type: Boolean,
    default: false,
  },
  isParameterizedForm: {
    type: Boolean,
    default: false,
  },
  uploadToAnotherCompany: {
    type: Boolean,
    default: false,
  },
  showUserDocuments: {
    type: Boolean,
    default: true,
  },
  defaultSuccessMsg: {
    type: String,
    default: "signup.parameterized_registration.success_docs",
  },
});

const { getUserDocuments, docTypes } = useGetters({
  getUserDocuments: "account/getUserDocuments",
  docTypes: "account/getDocTypes",
});
</script>
