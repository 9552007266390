export const isOdd = (num) => num % 2;

export function formatOptions(options, userDocuments, field = "description") {
  return options
    .map((option) => ({
      value: option.id,
      label: option[field],
      slug: option.slug,
      image: option.file_image,
      instructions: option.upload_instructions,
      validation: option.validation,
    }))
    .filter(
      (option) => !userDocuments.some((doc) => doc.slug_doc === option.slug)
    );
}

export const getDocName = (slug, docTypes) => {
  const doc = docTypes.find((doc) => doc.slug === slug);
  return doc
    ? doc.description.length > 30
      ? doc.description.slice(0, 30) + "..."
      : doc.description
    : "";
};

export const validExtensions = (validationStr) => {
  if (validationStr) {
    const regex = /mimes:([a-z,]+)/,
      match = validationStr.match(regex);

    if (match) {
      const extensions = match[1].split(",");
      return extensions.map((ext) => "." + ext.trim()).join(", ");
    }
  }
  return "";
};

export function convertMaxValidationToMB(validationRules) {
  // Procurar pela regra 'max' e extrair seu valor
  const maxRule = validationRules.find((rule) => rule.startsWith("max:"));

  if (!maxRule) {
    return null; // Retornar null ou outro valor se 'max' não estiver presente
  }

  // Extrair o valor numérico da regra 'max'
  const maxKB = parseInt(maxRule.split(":")[1], 10);

  if (isNaN(maxKB)) {
    return null; // Retornar null ou lançar um erro se o valor não for um número
  }

  // Converter KB para MB (1 MB = 1024 KB)
  const maxMB = maxKB / 1024;

  return maxMB;
}


export const validateFileSize = (file, maxSizeMB) => {
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    return file.size <= maxSizeBytes;
  };