<template>
  <q-card :flat="docsLengthIsMoreThenOne" :bordered="!docsLengthIsMoreThenOne">
    <q-card-section>
      <div
        v-if="doc.instructions"
        v-html="doc.instructions"
        class="text-justify"
        style="font-size: 10pt"
      />
      <div class="text-justify" v-else v-html="getUploadMessage(doc)" />

      <div class="text-center q-mt-lg" v-if="doc.image">
        <div class="image-container">
          <img
            :src="doc.image"
            :alt="doc.label"
            class="image"
            @click="openImage(doc.image)"
          />
          <span class="overlay-text">Abrir imagem</span>
        </div>
      </div>
    </q-card-section>
    <q-card-actions align="center">
      <div class="q-mb-xs" v-if="!in_request">
        <q-btn
          class="q-mb-md upload-btn"
          color="black"
          icon="file_upload"
          :label="$t('global.upload_doc')"
          @click="selectFile"
        />
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          :accept="mimes"
          @change="onFileSelected"
        />
      </div>
      <div v-else style="width: 200px">
        <q-linear-progress
          indeterminate
          color="grey-5"
          class="q-mt-sm q-pa-md q-mb-md"
        />
      </div>
    </q-card-actions>
    <div
      v-if="validationMessage"
      class="q-pa-md bg-red-4 text-white text-center"
      v-html="validationMessage"
    />
  </q-card>
</template>

<script setup>
import { uploadServices } from "../setup/uploadWithCustomJwt";
import { useActions, useGetters } from "vuex-composition-helpers";
import { fetchUploadFileToS3 } from "@/shared/helpers/s3Helpers";
import { notifySuccess } from "@/shared/helpers/notify";
import { ref, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import {
  validExtensions,
  convertMaxValidationToMB,
  validateFileSize,
} from "../setup";

const props = defineProps({
  doc: {
    type: Object,
    required: true,
  },
  isParameterizedForm: {
    type: Boolean,
    default: false,
  },
  fetchOrUpdateUser: {
    type: Boolean,
    default: false,
  },
  uploadToAnotherCompany: {
    type: Boolean,
    default: false,
  },
  docsLengthIsMoreThenOne: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const validationMessage = ref("");
const in_request = ref(false);
const fileInput = ref(null);
const file = ref(null);

function getUploadMessage(data) {
  // Parse a string em JSON
  const validation = JSON.parse(data.validation);

  // Pega o valor `max` em KB e converte para MB
  const maxSizeKB = validation.validation.find((rule) =>
    rule.startsWith("max:")
  );
  const maxSizeMB = maxSizeKB ? parseInt(maxSizeKB.split(":")[1]) / 1024 : null;

  // Retorna a mensagem de upload melhorada
  return `Por favor, faça o upload de um arquivo ou imagem legível, com tamanho máximo de ${maxSizeMB} MB.`;
}
const maxSizeMB = ref(
  convertMaxValidationToMB(JSON.parse(props.doc.validation).validation)
);
const mimes = ref(validExtensions(props.doc.validation));

const { userId, getTemporaryUploadLinks } = useGetters({
    userId: "auth/userPartnerId",
    getTemporaryUploadLinks: "missions/getTemporaryUploadLinks",
  }),
  {
    fetchUploadDoc,
    fetchUserAction,
    fetchTemporaryUploadLinks,
    updateUserPartnerAction,
    fetchDocDataToCustomJwt,
  } = useActions({
    fetchUploadDoc: "account/fetchUploadDoc",
    fetchUserAction: "auth/fetchUserAction",
    fetchTemporaryUploadLinks: "missions/fetchTemporaryUploadLinks",
    updateUserPartnerAction: "account/updateUserPartnerAction",
    fetchDocDataToCustomJwt: "account/fetchDocDataToCustomJwt",
  });

// Função para abrir o seletor de arquivos
const selectFile = () => fileInput.value.click();

const onFileSelected = (event) => {
  const selectedFile = event.target.files[0];

  if (selectedFile) {
    if (!validateFileSize(selectedFile, maxSizeMB.value)) {
      validationMessage.value = t("account.my_docs.exedeed_limit", {
        value: maxSizeMB.value,
      });

      // Faz com que a mensagem de erro desapareça após 3 segundos
      setTimeout(() => {
        validationMessage.value = "";
      }, 3000);

      return;
    }

    validationMessage.value = "";
    file.value = selectedFile;
    submit();
  }
};

const submit = () => {
  if (!file.value) return;

  const payload = {
    valid: 0,
    observation: "",
    partner_id: userId.value,
    slug_doc: props.doc.slug,
  };

  in_request.value = true;
  const fileAttributes = getFileAttributes(file.value);

  if (props.uploadToAnotherCompany) {
    /*
     * Cenário utilizado no dashboard centralizado
     */
    customJwtUploadDoc(fileAttributes, payload);
  } else {
    commonUploadDoc(fileAttributes, payload);
  }
};

const commonUploadDoc = (fileAttributes, payload) => {
  fetchTemporaryUploadLinks(fileAttributes)
    .then(() => {
      fetchUploadFileToS3(
        getTemporaryUploadLinks.value.url_pre_signed,
        file.value,
        fileAttributes
      ).then(() => {
        payload.doc_url = getTemporaryUploadLinks.value.url;
        fetchUploadDoc(payload)
          .then(async () => {
            notifySuccess(t("account.my_docs.success"));
            await fetchOrUpdateAndFetchUser();
            clearData();
          })
          .finally(() => (in_request.value = false));
      });
    })
    .catch((error) => console.log(error));
};

const customJwtUploadDoc = (fileAttributes, payload) => {
  let preSignedUrl = "";
  uploadServices
    .fetchTempUploadUrl(fileAttributes)
    .then((res) => {
      preSignedUrl = res.data.url_pre_signed;
      fetchUploadFileToS3(preSignedUrl, file.value, fileAttributes).then(() => {
        payload.doc_url = res.data.url;
        uploadServices
          .fetchCustomUploadDocs(payload)
          .then(async () => {
            await fetchDocDataToCustomJwt().then(() => {
              notifySuccess(t("account.my_docs.success"));
              clearData();
            });
          })
          .finally(() => (in_request.value = false));
      });
    })
    .catch((error) => console.log(error));
};

const fetchOrUpdateAndFetchUser = async () => {
  if (props.fetchOrUpdateUser && props.isParameterizedForm) {
    await updateUserPartnerAction({});
  } else {
    await fetchUserAction();
  }
};

const clearData = () => {
  file.value = null;
  mimes.value = "";
  validationMessage.value = "";
};

const defineFileType = (file) => file.type.split("/")[0];

const getFileAttributes = (file) => {
  const file_size = file.size,
    extension = file.name.split(".").pop(),
    file_type = defineFileType(file);

  return {
    file_type,
    file_size,
    extension,
    module: "partner_doc",
  };
};

const openImage = (imageLink) => {
  window.open(imageLink, "_blank");
};
</script>

<style scoped>
.upload-btn {
  width: 220px;
}

.hidden {
  display: none;
}
.image-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.image {
  width: 220px;
  transition: 0.4s;
}

.image:hover {
  scale: 1.02;
  filter: brightness(0.5);
}

/* Estilos para o texto que aparece no hover */
.overlay-text {
  position: absolute;
  color: white;
  font-size: 16px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.4s;
}

/* Mostrar o texto no hover */
.image-container:hover .overlay-text {
  opacity: 1;
}
</style>
