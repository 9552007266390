<template>
  <q-list class="rounded-borders">
    <template
      v-for="(doc, i) in formatOptions(docTypes, getUserDocuments)"
      :key="i"
    >
      <q-expansion-item
        expand-separator
        :label="doc.label"
        header-class="bg-grey-2 text-black"
        expand-icon-class="text-black"
      >
        <CardToSendDoc
          :doc="doc"
          :fetchOrUpdateUser="fetchOrUpdateUser"
          :isParameterizedForm="isParameterizedForm"
          :uploadToAnotherCompany="uploadToAnotherCompany"
        />
      </q-expansion-item>
      <q-separator />
    </template>
  </q-list>
</template>

<script setup>
import { useGetters } from "vuex-composition-helpers";
import { formatOptions } from "../setup";

import CardToSendDoc from "./CardToSendDoc.vue";

import { defineProps } from "vue";

defineProps({
  fetchOrUpdateUser: {
    type: Boolean,
    default: false,
  },
  isParameterizedForm: {
    type: Boolean,
    default: false,
  },
  uploadToAnotherCompany: {
    type: Boolean,
    default: false,
  },
});

const { getUserDocuments, docTypes } = useGetters({
  getUserDocuments: "account/getUserDocuments",
  docTypes: "account/getDocTypes",
});
</script>
